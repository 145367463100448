import {
  DialogHTMLAttributes,
  PropsWithChildren,
  ReactNode,
  useRef,
} from "react";
import clsx from "clsx";
import { Close } from "nvs-ds-icons/react/navigation";
import { IconContainer, IconSize } from "../IconContainer";

export type StatusBannerProps = {
  copy: string;
  dialogId: string;
  ariaLabel: string;
  className?: string;
  link?: ReactNode;
  bannerType?: StatusBannerTypes;
  onClose?: () => void;
} & DialogHTMLAttributes<HTMLDialogElement>;

export enum StatusBannerType {
  black = "black",
  white = "white",
  blue = "blue",
}

export type StatusBannerTypes = keyof typeof StatusBannerType;

const MAP_BANNER_TYPE_TO_CLASS = {
  black: "bg-gray-950 text-white dark:bg-white dark:text-gray-950",
  white: "bg-white text-gray-950 dark:bg-gray-950 dark:text-white",
  blue: "bg-blue-500 text-white",
};

const MAP_BANNER_BUTTON_TYPE_TO_CLASS = {
  white:
    "hover:bg-gray-950 hover:text-white hover:dark:bg-white hover:dark:text-gray-950",
  black:
    "hover:bg-white hover:text-gray-950 hover:dark:bg-gray-950 hover:dark:text-white",
  blue: "hover:bg-white hover:text-blue-500",
};

function getClassByBannerButtonType(type: StatusBannerTypes) {
  return MAP_BANNER_BUTTON_TYPE_TO_CLASS[type];
}

function getClassByBannerType(type: StatusBannerTypes) {
  return MAP_BANNER_TYPE_TO_CLASS[type];
}

export function StatusBanner({
  bannerType = StatusBannerType.black,
  open,
  ...props
}: PropsWithChildren<StatusBannerProps>) {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const descriptionId = `${props.dialogId}-description`;
  const titleId = `${props.dialogId}-title`;

  return (
    <dialog
      open={open}
      ref={dialogRef}
      aria-labelledby={titleId}
      aria-describedby={descriptionId}
      className={clsx(
        "w-full relative", // do not add any display styles, this will break the dialog
        props.className,
        getClassByBannerType(bannerType),
      )}
    >
      <div className="w-full p-xs flex justify-between items-center">
        <div className="font-helvetica-mono uppercase text-sm w-full leading-none pt-1 flex flex-wrap items-center justify-center">
          {/* Screen readers only */}
          <h2 className="sr-only" id={titleId}>
            {props.ariaLabel}
          </h2>
          <p id={descriptionId}>
            {props.copy}{" "}
            <span className="border-b border-current whitespace-nowrap">
              {props.link}
            </span>
          </p>
        </div>
        <form method="dialog" className="flex items-center">
          <button
            type="button"
            aria-label={`Close dialog "${props.ariaLabel}"`}
            className={getClassByBannerButtonType(bannerType)}
            onClick={() => {
              props.onClose?.();
              dialogRef.current?.close && dialogRef.current.close();
            }}
          >
            <IconContainer size={IconSize.sm}>
              <Close />
            </IconContainer>
          </button>
        </form>
      </div>
    </dialog>
  );
}
